exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ShareButton__iconTwitter--ENKnL {\n  color: #fff;\n  font-size: 25px;\n  padding: 10px;\n  border-radius: 50%;\n  display: inline-block;\n  margin-right: 15px;\n  width: 45px;\n  height: 45px;\n  box-sizing: border-box;\n  background-color: #4195ff;\n}\n\n.ShareButton__iconFacebook--2g26o {\n  color: #fff;\n  font-size: 25px;\n  padding: 10px;\n  border-radius: 50%;\n  display: inline-block;\n  margin-right: 15px;\n  width: 45px;\n  height: 45px;\n  box-sizing: border-box;\n  background-color: #354c8d;\n}\n\n@media (max-width: 768px) {\n  .ShareButton__codeContainer--2cJTE {\n    height: 130px;\n  }\n  .ShareButton__codeContainer--2cJTE .ShareButton__code--1gpvx {\n    width: 100%;\n  }\n  .ShareButton__codeContainer--2cJTE .ShareButton__shareButton--2sosS {\n    display: block;\n    width: 100%;\n  }\n  .ShareButton__iconFacebook--2g26o,\n  .ShareButton__iconTwitter--ENKnL {\n    display: block;\n    margin: 0 auto;\n    margin-bottom: 7px;\n  }\n}", ""]);

// exports
exports.locals = {
	"iconTwitter": "ShareButton__iconTwitter--ENKnL",
	"iconFacebook": "ShareButton__iconFacebook--2g26o",
	"codeContainer": "ShareButton__codeContainer--2cJTE",
	"code": "ShareButton__code--1gpvx",
	"shareButton": "ShareButton__shareButton--2sosS"
}; 
var style = module.exports.toString();module.exports = exports.locals || { stylesheet: "" };if (!module.exports.stylesheet) { module.exports.stylesheet = style; };