import PropTypes from 'prop-types';
import React from 'react';
import {
  ContentCard,
  ContentCardFooter,
  ContentCardText,
  ContentCardHeader,
  ContentCardImage,
  TheScoreContentCardSource,
} from 'sports-react';

import Link from '~/components/Link/Link';
import ArticleTag from '~/components/ArticleTag/ArticleTag';
import StyledComponent from '~/components/Styled/Styled';
import css from './TheScoreArticleCard.scss';

const TheScoreArticleCard = props => (
  <ContentCard>
    <Link route={props.webUri} onClick={props.onClick} prefetch>
      <a>
        <ContentCardHeader isLarge={props.isLarge} iconType="read" />
        {props.imageURL ? (
          <ContentCardImage
            alt={props.imageAltText}
            src={props.imageURL}
            styleOverrides={
              props.styleOverrides ? props.styleOverrides.contentCardImageStyles : null
            }
          />
        ) : (
          <div className={css.headerBreak} />
        )}
        <ContentCardText
          isImageCard={!!props.imageURL}
          isLarge={props.isLarge}
          text={props.text}
          styleOverrides={props.styleOverrides ? props.styleOverrides.contentCardTextStyles : null}
        />
      </a>
    </Link>
    <ContentCardFooter
      publishedAt={props.publishedAt}
      source={
        <TheScoreContentCardSource
          styleOverrides={
            props.styleOverrides ? props.styleOverrides.theScoreContentCardSourceStyles : null
          }
        />
      }
      shareText={props.text}
      shareUrl={props.shareUrl}
      isLarge={props.isLarge}
      styleOverrides={props.styleOverrides ? props.styleOverrides.contentCardFooterStyles : null}
    />
    <ArticleTag leagueSlug={props.leagueSlug} isLarge={props.isLarge} />
    {props.messengerShareButton}
  </ContentCard>
);

TheScoreArticleCard.propTypes = {
  imageAltText: PropTypes.string,
  imageURL: PropTypes.string,
  messengerShareButton: PropTypes.node,
  publishedAt: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  shareUrl: PropTypes.string,
  contentId: PropTypes.number,
  onClick: PropTypes.func,
  webUri: PropTypes.string.isRequired,
  leagueSlug: PropTypes.string,
  isLarge: PropTypes.bool,
  styleOverrides: PropTypes.object,
};

TheScoreArticleCard.defaultProps = {
  imageAltText: '',
  imageURL: null,
  messengerShareButton: null,
  shareUrl: null,
  contentId: null,
  onClick: null,
  isLarge: false,
  styleOverrides: null,
  leagueSlug: null,
};

export default StyledComponent(TheScoreArticleCard, [css]);
