import { Experiment, Ops } from 'planout';
import logEvent from '~/util/analytics';

class ArticleMatchupExperiment extends Experiment {
  setup() {
    this.setName('ArticleMatchupExperiment');
    this.setSalt('v1');
  }

  configureLogger() {
    return this;
  }

  log(event) {
    if (event.event === 'exposure') {
      logEvent('experiment', {
        type: 'exposure',
        experiment_name: 'article_matchup_experiment',
        ...event.params,
      });
    }
    return this;
  }

  previouslyLogged() {
    // check if we’ve already logged an event for this user
    return this._exposureLogged; // eslint-disable-line no-underscore-dangle
  }

  getParamNames() {
    return this.getDefaultParamNames();
  }

  assign(params, args) {
    params.set(
      'variant',
      new Ops.Random.UniformChoice({
        choices: ['show', 'hide'],
        unit: args.userId,
      })
    );

    return this;
  }
}

export default ArticleMatchupExperiment;
