exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Article__article--2pJwZ .Article__byline-link--O3fEO {\n  color: #fff;\n}\n.Article__article--2pJwZ .article-segment--video,\n.Article__article--2pJwZ .article-segment--image {\n  display: table !important;\n  margin-left: auto;\n  margin-right: auto;\n  flex-wrap: wrap;\n}\n.Article__article--2pJwZ .article-segment--image img,\n.Article__article--2pJwZ video {\n  max-width: 100%;\n  max-height: 230px !important;\n  height: auto;\n  width: auto;\n}\n.Article__article--2pJwZ .article-segment--image-caption {\n  font-family: \"Inter\", sans-serif;\n  font-weight: 700;\n  color: #f9f9f9;\n  font-size: 12px;\n  font-weight: 400;\n  display: table-caption;\n  caption-side: bottom;\n  color: #3a3d40;\n  font-size: 12px;\n}\n.Article__article--2pJwZ .article-segment--image-attribution {\n  color: #aaa;\n  font-style: italic;\n}\n.Article__article--2pJwZ .article-segment--embed {\n  flex-wrap: wrap;\n}\n.Article__article--2pJwZ .article-segment--embed p {\n  margin: 5px 0 !important;\n}\n.Article__article--2pJwZ .table-responsive {\n  margin-bottom: 5px;\n  width: 100%;\n  overflow-x: auto;\n  overflow-y: hidden;\n  border: none;\n}\n.Article__article--2pJwZ .table-responsive table {\n  border-collapse: collapse;\n  margin-bottom: 0;\n  font-weight: 500;\n  font-size: 18px;\n  white-space: nowrap;\n}\n.Article__article--2pJwZ .table-responsive th {\n  padding: 8px;\n  border-bottom: 2px solid #ddd;\n  min-height: 45px;\n  background-color: #f9f9f9;\n  color: #888;\n  font-weight: 500;\n  font-size: 13px;\n}\n.Article__article--2pJwZ .table-responsive td {\n  padding: 8px;\n  vertical-align: top;\n  border-top: none;\n  border-bottom: #f9f9f9 1px solid;\n  font-size: 13px;\n}\n.Article__article--2pJwZ .table-responsive thead {\n  text-transform: uppercase;\n}\n.Article__article--2pJwZ p {\n  font-weight: 300;\n}\n\n@media (min-width: 768px) {\n  .Article__article--2pJwZ {\n    border-radius: 4px;\n    border: solid 1px #000;\n  }\n}", ""]);

// exports
exports.locals = {
	"article": "Article__article--2pJwZ",
	"byline-link": "Article__byline-link--O3fEO"
}; 
var style = module.exports.toString();module.exports = exports.locals || { stylesheet: "" };if (!module.exports.stylesheet) { module.exports.stylesheet = style; };