import React from 'react';
import { connect } from 'react-redux';
import { getEvent } from '~/util/getEvents';

import { Parametrize } from 'react-experiments';
import getOr from '~/util/getOr';

import find from 'lodash/find';

import Article from '~/components/Article/Article';
import ArticleMatchupExperiment from '~/experiments/ArticleMatchup/ArticleMatchupExperiment';

interface Props {
  currentCard?: boolean;
  contentId?: number;
  isLarge?: boolean;
  article: Article;
  styleOverrides?: {
    theScoreArticleCardStyles?: any;
  };
  userId: string;
  showTag?: boolean;
}

interface State {
  event: Event;
  exp: any;
}

class ArticleContainer extends React.Component<Props, State> {
  static displayName = 'ArticleContainer';
  static defaultProps = {
    contentId: null,
    currentCard: false,
    isLarge: false,
    styleOverrides: null,
    showTag: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      event: null,
      exp: null,
    };
  }

  getEventUri() {
    const resourceTags = getOr(this.props.article, 'resource_tags', null);
    const eventUri = resourceTags
      ? find(resourceTags, tag => tag.uri.match(/.*\/events\/.*/g))
      : null;

    return eventUri;
  }

  getMatchup() {
    const eventUri = this.getEventUri();

    if (eventUri && this.state.exp && this.state.exp.get('variant') === 'show') {
      const [, eventLeagueSlug, , eventId] = eventUri.uri.split('/');

      getEvent(eventLeagueSlug, eventId).then(event => {
        if (getOr(this.state.event, 'id', null) !== eventId) this.setState({ event: event.json });
      });
    } else {
      this.setState({ event: null });
    }
  }

  componentWillMount() {
    const eventUri = this.getEventUri();

    if (this.props.currentCard && eventUri) {
      // @ts-ignore
      const exp = new ArticleMatchupExperiment({
        userId: this.props.userId || 'test',
      });

      this.setState({ exp });
    }
  }

  componentDidMount() {
    this.getMatchup();
  }

  componentDidUpdate(oldProps) {
    if (oldProps.article.id !== this.props.article.id) {
      this.getMatchup();
    }
  }

  render() {
    if (this.state.exp) {
      return (
        <Parametrize experiment={this.state.exp} params={['variant']}>
          <Article {...this.props} matchup={this.state.event} showTag={this.props.showTag} />
        </Parametrize>
      );
    }

    return <Article {...this.props} matchup={this.state.event} showTag={this.props.showTag} />;
  }
}

const mapStateToProps = state => ({
  userId: state.abTest.data.userId,
});

export default connect(mapStateToProps)(ArticleContainer);
