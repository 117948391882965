import React from 'react';
import StyledComponent from '~/components/Styled/Styled';
import logEvent from '~/util/analytics';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import css from '~/components/ShareButton/ShareButton.scss';

class ShareButton extends React.Component {
  static defaultProps = {
    className: '',
  };

  constructor(props) {
    super(props);

    this.onProxyClick = this.onProxyClick.bind(this);
  }

  onProxyClick() {
    const { name } = this.props;

    switch (name) {
      case 'Facebook':
        this.popupFacebook();
        break;
      case 'SMS':
        this.sendSms();
        break;
      case 'E-Mail':
        this.sendEmail();
        break;
      case 'WhatsApp':
        this.shareWhatsapp();
        break;
      default:
        this.windowOpen();
    }

    logEvent('share', { type: 'article', social_network: name });
  }

  popupFacebook() {
    if (window.FB) {
      window.FB.ui(
        {
          method: 'share',
          href: this.props.url,
        },
        () => {}
      );
    } else {
      this.windowOpen();
    }
  }

  sendSms() {
    const ua = navigator.userAgent;
    let url = this.formattedUrl();

    if (ua.match(/(ipad|iphone)/i)) {
      url = url.replace('sms:?', 'sms:&');
    }

    location.href = url;
  }

  sendEmail() {
    location.href = this.formattedUrl();
  }

  shareWhatsapp() {
    location.href = this.formattedUrl();
  }

  windowOpen(url = this.formattedUrl()) {
    const newWindow = window.open();

    newWindow.opener = null; /* Prevent vulnerability */
    newWindow.location = url;
  }

  formattedUrl() {
    const { url, text, urlFormat } = this.props;

    return urlFormat
      .replace('%URL', encodeURIComponent(url))
      .replace('%TEXT', encodeURIComponent(text));
  }

  render() {
    const { className, iconClass, label } = this.props;
    const buttonClasses = classnames(css.button, className);
    const iconClasses = classnames(css.icon, 'icon', `icon-${iconClass}`);

    return (
      <button className={buttonClasses} onClick={this.onProxyClick}>
        <i className={iconClasses} />
        <span className="sr-only">{label}</span>
      </button>
    );
  }
}

ShareButton.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  iconClass: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  urlFormat: PropTypes.string.isRequired,
};

ShareButton.displayName = 'ShareButton';

export default StyledComponent(ShareButton, [css]);
