exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".TheScoreArticleCard__headerBreak--1gdwg {\n  margin-bottom: 25px;\n}", ""]);

// exports
exports.locals = {
	"headerBreak": "TheScoreArticleCard__headerBreak--1gdwg"
}; 
var style = module.exports.toString();module.exports = exports.locals || { stylesheet: "" };if (!module.exports.stylesheet) { module.exports.stylesheet = style; };