exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ArticleTag__ArticleTag--3TyA3 {\n  font-weight: bold;\n  font-size: 10px;\n  text-transform: uppercase;\n  height: 18px;\n  line-height: 1;\n  letter-spacing: 2px;\n  color: #f9f9f9;\n  border-radius: 2px;\n  padding: 0 4px;\n  background-color: rgba(0, 120, 253, 0.75);\n  align-items: center;\n  display: flex;\n  position: absolute;\n  z-index: 1;\n  top: 10px;\n  left: 10px;\n}\n\n.ArticleTag__isLarge--2pfQD.ArticleTag__ArticleTag--3TyA3 {\n  padding: 0 8px;\n  font-size: 13px;\n  top: 20px;\n  left: 20px;\n}\n\n.ArticleTag__tagText--2yyvV {\n  margin-right: -2px;\n}", ""]);

// exports
exports.locals = {
	"ArticleTag": "ArticleTag__ArticleTag--3TyA3",
	"isLarge": "ArticleTag__isLarge--2pfQD",
	"tagText": "ArticleTag__tagText--2yyvV"
}; 
var style = module.exports.toString();module.exports = exports.locals || { stylesheet: "" };if (!module.exports.stylesheet) { module.exports.stylesheet = style; };