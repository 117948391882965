import React from 'react';
import ReactDOM from 'react-dom';
import { TheScoreArticleContentCardDetail } from 'sports-react';
// @ts-ignore
import { Link } from '~/routes';

import Ad, { AdTypes } from '~/components/Ad/Ad';
import TheScoreArticleCard from '~/components/TheScoreArticleCard/TheScoreArticleCard';
import StyledComponent from '~/components/Styled/Styled';
import ShareLinks from '~/components/ShareLinks/ShareLinks';
import logEvent from '~/util/analytics';
import isNil from 'lodash/isNil';
import find from 'lodash/find';
import kebabCase from 'lodash/kebabCase';

import getOr from '~/util/getOr';
import userLocation from '~/util/userLocation';

import { withExperimentParams } from 'react-experiments';
import css from '~/components/Article/Article.scss';

const getArticleFeatureImageURL = article => {
  if (article.feature_image) {
    return article.feature_image.w640xh480.url;
  }
  return '';
};

const getWebUri = article => {
  const slug = article.web_league_slug;
  const id = article.id;

  return slug
    ? `/${slug}/news/${id}/${kebabCase(article.title.toLowerCase())}`
    : `/news/${id}/${kebabCase(article.title.toLowerCase())}`;
};

interface Props {
  currentCard?: boolean;
  contentId?: number;
  isLarge?: boolean;
  article: Article;
  styleOverrides?: {
    theScoreArticleCardStyles?: any;
  };
  userId: string;
  matchup?: Event;
  variant?: string;
  showTag?: boolean;
}

interface ArticleState {
  region: string;
}

export class ArticleComponent extends React.Component<Props, ArticleState> {
  static displayName = 'Article';
  static defaultProps = {
    contentId: null,
    currentCard: false,
    isLarge: false,
    styleOverrides: null,
    matchup: null,
    variant: null,
    showTag: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      region: 'global',
    };
  }

  componentDidMount() {
    userLocation.promise.then(location => {
      this.setRegion(location.countryCode);
    });

    this.showTeadsAd();
  }

  componentDidUpdate(oldProps) {
    if (oldProps.article.id !== this.props.article.id) {
      this.showTeadsAd();
    }
  }

  setRegion(region) {
    this.setState({ region: region.toLowerCase() });
  }

  showTeadsAd() {
    if (typeof document === 'undefined') return;

    const adUnitSelector = this.props.article.ad_unit_selector;
    const adUnitDomNode = document.getElementsByClassName(adUnitSelector)[0];

    if (!adUnitDomNode) return;

    const teadsAd = (
      <Ad
        leagueSlug={this.props.article.web_league_slug}
        adType={AdTypes.TEADS}
        section="news"
        articleId={this.props.article.id}
      />
    );

    ReactDOM.render(teadsAd, adUnitDomNode);
  }

  render() {
    const {
      article,
      currentCard,
      contentId,
      isLarge,
      styleOverrides,
      variant,
      matchup,
    } = this.props;

    const handleClick = () => {
      if (isNil(contentId)) {
        return;
      }

      logEvent('card_click', {
        article_id: contentId,
        featured_article: currentCard,
        sourced: false,
      });
    };

    const imageAltText = article.feature_image_alt_text || '';

    if (currentCard) {
      const bylineNames = article.byline ? article.byline.split(', ') : [];
      return (
        <div className={css.article}>
          <TheScoreArticleContentCardDetail
            byline={bylineNames.map((name, index) => {
              let authorStringOrLink: JSX.Element | string = name;
              const currentAuthor = find(article.authors, author => author.name === name);
              if (currentAuthor) {
                authorStringOrLink = (
                  <Link route={`/author/${currentAuthor.slug}`} key={currentAuthor.slug}>
                    <a className={css['byline-link']}>{name}</a>
                  </Link>
                );
              }
              if (index === bylineNames.length - 1) return [authorStringOrLink];
              return [authorStringOrLink, ', '];
            })}
            content={article.content}
            imageAttribution={article.feature_image_attribution}
            imageAltText={imageAltText}
            imageURL={getArticleFeatureImageURL(article)}
            publishedAt={article.published_at}
            text={article.title}
            sponsors={article.sponsorships}
            region={this.state.region}
            isLarge={isLarge}
            event={variant === 'show' ? matchup : null}
            onMatchupCardClick={() =>
              logEvent('click', {
                type: 'article_matchup',
                article_id: getOr(this, 'props.article.id', null),
                event_id: getOr(this, 'state.event.id', null),
              })
            }
          />
          {article.share_url && <ShareLinks url={article.share_url} text={article.title} />}
        </div>
      );
    }

    return (
      <TheScoreArticleCard
        imageAltText={imageAltText}
        imageURL={getArticleFeatureImageURL(article)}
        publishedAt={article.published_at}
        text={article.title}
        shareUrl={article.share_url}
        contentId={contentId}
        onClick={handleClick}
        webUri={getWebUri(article)}
        isLarge={isLarge}
        styleOverrides={styleOverrides ? styleOverrides.theScoreArticleCardStyles : null}
        leagueSlug={this.props.showTag ? article.web_league_slug : null}
      />
    );
  }
}

export default withExperimentParams(StyledComponent(ArticleComponent, [css]));
