const ALL = [
  {
    name: 'Twitter',
    buttonClass: 'twitter',
    iconClass: 'twitter',
    label: 'Twitter',
    urlFormat: 'https://twitter.com/intent/tweet?url=%URL&text=%TEXT&via=theScore',
    onMobile: true,
    onDesktop: true,
  },
  {
    name: 'Reddit',
    buttonClass: 'reddit',
    iconClass: 'reddit-full',
    label: 'Reddit',
    urlFormat: 'http://www.reddit.com/submit?url=%URL&title=%TEXT',
    onMobile: true,
    onDesktop: true,
  },
  {
    name: 'Facebook',
    buttonClass: 'facebook',
    iconClass: 'facebook',
    label: 'Facebook',
    urlFormat: 'https://www.facebook.com/sharer/sharer.php?u=%URL&t=%TEXT',
    onMobile: true,
    onDesktop: true,
  },
  {
    name: 'WhatsApp',
    buttonClass: 'whats-app',
    iconClass: 'whats-app',
    label: "What's App",
    urlFormat: 'whatsapp://send?text=%TEXT - %URL',
    onMobile: true,
    onDesktop: false,
  },
  {
    name: 'E-Mail',
    buttonClass: 'email',
    iconClass: 'mail',
    label: 'Email',
    urlFormat:
      "mailto:?subject=%TEXT&body=%URL %0A%0ASent via theScore - The best sports app for scores, news and alerts for the world's biggest sports leagues.",
    onMobile: true,
    onDesktop: true,
  },
  {
    name: 'SMS',
    buttonClass: 'sms',
    iconClass: 'sms',
    label: 'SMS',
    urlFormat: 'sms:?body=%TEXT%20-%20%URL',
    onMobile: true,
    onDesktop: false,
  },
];

const WWCPDYLL = [
  {
    name: 'Twitter',
    buttonClass: 'twitter',
    iconClass: 'twitter',
    label: 'Twitter',
    urlFormat: 'https://twitter.com/intent/tweet?url=%URL&text=%TEXT&via=theScore',
    onMobile: true,
    onDesktop: true,
    utm_source: 'twitter_share',
  },
  {
    name: 'Facebook',
    buttonClass: 'facebook',
    iconClass: 'facebook',
    label: 'Facebook',
    urlFormat: 'https://www.facebook.com/sharer/sharer.php?u=%URL&t=%TEXT',
    onMobile: true,
    onDesktop: true,
    utm_source: 'facebook_share',
  },
  {
    name: 'WhatsApp',
    buttonClass: 'whats-app',
    iconClass: 'whats-app',
    label: "What's App",
    urlFormat: 'whatsapp://send?text=%URL',
    onMobile: true,
    onDesktop: false,
    utm_source: 'whatsapp_share',
  },
  {
    name: 'SMS',
    buttonClass: 'sms',
    iconClass: 'sms',
    label: 'SMS',
    urlFormat: 'sms:?body=%URL',
    onMobile: true,
    onDesktop: false,
    utm_source: 'sms_share',
  },
];

export default {
  ALL,
  WWCPDYLL,
};
