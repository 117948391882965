import React from 'react';

import StyledComponent from '~/components/Styled/Styled';
import css from './ArticleTag.scss';

interface Props {
  leagueSlug: string;
  isLarge?: boolean;
}

class ArticleTag extends React.Component<Props> {
  static displayName = 'ArticleTag';
  static defaultProps = {
    isLarge: false,
  };

  render() {
    return (
      this.props.leagueSlug && (
        <a href={this.props.leagueSlug && `/${this.props.leagueSlug}/news`}>
          <div className={`${css.ArticleTag} ${this.props.isLarge && css.isLarge}`}>
            <div className={css.tagText} role="presentation">
              {this.props.leagueSlug}
            </div>
          </div>
        </a>
      )
    );
  }
}

export default StyledComponent(ArticleTag, [css]);
