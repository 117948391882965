import React from 'react';
import classnames from 'classnames';
import ShareButton from '~/components/ShareButton/ShareButton';
import StyledComponent from '~/components/Styled/Styled';
import ShareServices from '~/constants/ShareServices';
import SupportedLanguageCodes from '~/constants/SupportedLanguageCodes';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import includes from 'lodash/includes';
import { parse } from 'uri-js';

import css from '~/components/ShareLinks/ShareLinks.scss';

const getLocalizedURL = (url, locale) => {
  if (!SupportedLanguageCodes.includes(locale)) {
    return url;
  }

  const parsedURI = parse(url);
  const subdomain = includes(parsedURI.host, 'staging') ? `${locale}-staging` : `${locale}`;
  return `https://${subdomain}.thescore.com${parsedURI.path}`;
};

const ShareLinks = props => {
  const { url, text, styles, intl: { locale }, services } = props;
  const localizedURL = locale === 'en' ? url : getLocalizedURL(url, locale);
  return (
    <div className={css.container} style={styles}>
      <div className={css.buttonBar}>
        {services.map(service => {
          const buttonWrapperClasses = classnames(css.buttonWrapper, {
            'visible-xs': service.onMobile,
            'visible-sm visible-md visible-lg': service.onDesktop,
            'hidden-sm hidden-md hidden-lg': !service.onDesktop,
          });
          const buttonClasses = classnames(css.button, css[service.buttonClass]);
          let shareUrl = localizedURL;
          if (service.utm_source) {
            shareUrl = `${shareUrl}?utm_source=${service.utm_source}`;
          }
          return (
            <div key={`shareLink-${service.name}`} className={buttonWrapperClasses}>
              <ShareButton
                key={`share-button-${service.name}`}
                {...service}
                url={shareUrl}
                text={text}
                className={buttonClasses}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

ShareLinks.propTypes = {
  url: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  text: PropTypes.string,
  styles: PropTypes.object,
  services: PropTypes.array,
};

ShareLinks.defaultProps = {
  text: '',
  styles: null,
  services: ShareServices.ALL,
};

ShareLinks.displayName = 'ShareLinks';

export default injectIntl(StyledComponent(ShareLinks, [css]));
